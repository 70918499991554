import React, { useEffect, useState } from 'react';

function Showcase({ navStatus, navHandleClick }) {
  const [dynamicHeight, setDynamicHeight] = useState(
    window.innerHeight
  );

  useEffect(() => {
    function handleResize() {
      setDynamicHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className={
        navStatus === 'closed'
          ? 'showcase'
          : 'showcase showcase--active'
      }
      style={{ height: `${dynamicHeight}px` }}
    >
      <div className="showcase__header">
        {/* <h2 className="showcase__logo">
          <a href="#home">MT</a>
        </h2> */}
        {/* <div
          className={
            navStatus === 'closed'
              ? 'showcase__toggle'
              : 'showcase__toggle showcase__toggle--active'
          }
          onClick={navHandleClick}
        ></div> */}
      </div>

      <img
        className="showcase__image"
        src="/assets/showcase-bg.jpg"
        alt=""
      />

      <div className="showcase__overlay"></div>

      <div className="showcase__text">
        <h3 className="title title--sm uppercase letterSpacing ">
          Software Engineer
        </h3>
        <h2 className="title title--md uppercase letterSpacing  ">
          Mihai Timoficiuc
        </h2>
        <p className="text text--sm">
          With a hands-on approach, a talent for
          problem-solving and a goal of turning worthy ideas
          into reality, I develop software that delivers
          real-world value.
        </p>
        <a href="mailto:office@mihaitimoficiuc.com">
          <button className="button button--primary button--margin button--animated">
            Let's talk
          </button>
        </a>
      </div>

      <ul className="showcase__social">
        <li className="showcase__social-item">
          <a
            href="https://www.linkedin.com/in/mihai-timoficiuc-50625b1a4/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="showcase__social-icon"
              src="/assets/icons/icons8-linkedin-50.png"
              alt=""
            />
          </a>
        </li>
        <li className="showcase__social-item">
          <a
            href="https://github.com/MTimo27"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="showcase__social-icon"
              src="/assets/icons/icons8-github-50.png"
              alt=""
            />
          </a>
        </li>
        <li className="showcase__social-item">
          <a
            href="mailto:office@mihaitimoficiuc.com"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="showcase__social-icon"
              src="/assets/icons/icons8-gmail-50.png"
              alt=""
            />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Showcase;
