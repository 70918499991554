import React, { useState, useEffect } from 'react';
import CircleLoader from 'react-spinners/CircleLoader';
import Home from './pages/Home';

function App() {
  const [loading, setLoading] = useState(true);
  const [loadingMinTime, setLoadingMinTime] =
    useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadingMinTime(true);
    }, 1500);
    if (loadingMinTime) {
      setLoading(false);
      setLoadingMinTime(false);
    }
  }, [loadingMinTime]);

  const [nav, setNav] = useState('closed');
  const navHandleClick = () => {
    if (nav === 'closed') setNav('opened');
    else setNav('closed');
  };

  return (
    <>
      {loading && (
        <div className="loading">
          <CircleLoader
            color={'#ffff'}
            loading={true}
            css={`
              display: block;
              border-color: #ffff;
            `}
            size={120}
          />
        </div>
      )}
      <div
        className="app"
        style={{ display: `${loading ? 'none' : 'block'}` }}
      >
        <div className="app__main">
          {/* <Navbar navHandleClick={navHandleClick} /> */}
          <Home
            navStatus={nav}
            navHandleClick={navHandleClick}
          />
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default App;
