import React from 'react';
import Showcase from '../components/Showcase';

function Home({ navStatus, navHandleClick }) {
  return (
    <div
      className="home"
      id="home"
    >
      <Showcase
        navStatus={navStatus}
        navHandleClick={navHandleClick}
      />
    </div>
  );
}

export default Home;
