import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/css/main.css';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);
